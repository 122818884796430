var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"s3m-product_list"},[_c('h1',[_vm._v("Kurs-Bundles")]),_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"rounded":"","color":"primary","dark":""},on:{"click":_vm.createUser}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Kurs-Bundle ")],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.products,"search":_vm.search,"items-per-page":_vm.itemsPerPage,"hide-default-footer":_vm.products.length < 5},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.author",fn:function(ref){
var item = ref.item;
return [(item.author)?_c('v-avatar',{staticClass:"mr-1",staticStyle:{"color":"white","font-weight":"bolder"},attrs:{"color":"warning","size":"35","small":""}},[_vm._v(_vm._s(_vm._f("uppercaseFirstLetter")(item.author.firstName))+_vm._s(_vm._f("uppercaseFirstLetter")(item.author.lastName)))]):_vm._e(),(item.author)?[_vm._v(_vm._s(item.author.firstName)+" "+_vm._s(item.author.lastName))]:_vm._e()]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatLocaleDate")(item.updatedAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatLocaleDate")(item.updatedAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.copyItem(item)}}},[_vm._v(" mdi-content-copy ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1),_c('product-edit-dialog',{ref:"productEditDialog",on:{"updated":_vm.fetchProducts}}),_c('confirm-dialog',{ref:"confirmDelete"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }