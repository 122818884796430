var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"s3m-license_list"},[_c('h1',[_vm._v("Lerngruppen")]),_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"rounded":"","color":"primary","dark":""},on:{"click":_vm.createUser}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Gruppe ")],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.licences,"search":_vm.search,"items-per-page":_vm.itemsPerPage,"hide-default-footer":_vm.licences.length < 5},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.dateOfPurchase",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatLocaleDate")(item.dateOfPurchase))+" ")]}},{key:"item.owner",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.owner.firstName)+" "+_vm._s(item.owner.lastName)+" ")]}},{key:"item.orderNumber",fn:function(ref){
var item = ref.item;
return [(_vm.isLicenceCommentObjectId(item.orderNumber))?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"no-wrap"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editLicenceAssignment(item)}}},[_vm._v(" mdi-account-plus ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])],1)]}}])})],1),_c('licence-edit-dialog',{ref:"licencesEditDialog",on:{"updated":_vm.fetchLicences}}),_c('licence-assign-dialog',{ref:"licencesAssignDialog"}),_c('confirm-dialog',{ref:"confirmDelete"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }