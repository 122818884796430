<template>
  <div class="s3m-licence_edit">
    <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="600px"
        content-class="s3m-license_edit_dialog"
    >
      <v-card>

        <v-card-title>
          <span class="headline">Lerngruppe {{licence.id ? 'bearbeiten' : 'erstellen'  }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="productForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                      v-if="!licence.id"
                      v-model="licence.product"
                      :items="products"
                      outlined
                      label="Kurs-Bundle"
                      item-text="name"
                      item-value="id"
                      :filter="productFilter"
                      :rules="requiredRule('Produkt')"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.number"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                  <div v-if="licence.id">
                    <div class="text-h6">Produkt</div>
                    {{licence.product.name}}
                  </div>
                </v-col>
                <v-col cols="12">
                  <user-select
                      v-if="!licence.id"
                      :value.sync="licence.owner"
                      :users="filteredUsers"
                      label="Anbieter"
                      :rules="requiredRule('Kunde')"
                  />
                  <div v-if="licence.id">
                    <div>Verfügbare Plätze: {{licence.quantityRemaining}}</div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model.number="licence.quantityTotal"
                      label="Anzahl möglicher Teilnehmer"
                      :rules="requiredRule('Anzahl')"
                      @change="validateLicenceQuantity($event)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <date-picker label="Startdatum (Optional)" v-model="licence.startDate"/>
                </v-col>
                <v-col cols="12">
                  <date-picker label="Ablaufdatum (Optional)" v-model="licence.expirationDate"/>
                </v-col>
                <v-col cols="12" v-if="!isLicenceCommentObjectId">
                  <v-textarea
                      :no-resize="true"
                      rows="2"
                      label="Kommentar"
                      v-model="licence.comment"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-card>
                    <v-card-title>
                      <v-row>
                        <v-col>Mit Berechtigungsgruppe verbinden</v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                      Es werden alle User der Berechtigungsgruppe, <b>die einen InternetUser</b> besitzen, in die Lerngruppe übertragen. Mitglieder der Lerngruppe, die nicht in der Berechtigungsgruppe sind werden gelöscht!
                    </v-card-text>
                    <v-autocomplete
                        v-model="licence.orderNumber"
                        :items="dummySammelgruppen"
                        item-text="name"
                        item-value="id"
                        label=""
                        @change="handleSelectionChange"
                    ></v-autocomplete>
                    <v-card-text>
                      <v-btn class="mr-2" color="primary" @click="clearAssignments()">Verbindung aufheben</v-btn>
                      <v-btn color="primary" @click="doSync()">Manuell Synchronisieren</v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveLicence"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
      <Spinner v-if="showLoader" class="s3m-spinner" name="circle" color="#008bc4" />
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"></snackbar>
  </v-row>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import Snackbar from "@/components/Snackbar";
import DatePicker from "@/components/DatePicker";
import { propertySearch } from "@/utils/searchUtils";
import UserSelect from "@/components/UserSelect";
import APIServiceSync from "@/services/APIServiceSync";
const GROUP_INIT_DATA = {
  product: {},
  owner: {},
}
export default {
  name: 'LicenceEditDialog',
  components: {UserSelect, DatePicker, Snackbar, ConfirmDialog },
  data() {
    return {
      products: [],
      syncGroup: false,
      showDialog: false,
      filteredUsers: [],
      sammelgruppen: [],
      values: [],
      sortBy: 'name',
      sortDesc: false,
      search: '',
      value: null,
      licence: GROUP_INIT_DATA,
      saveSuccess: false,
      isProductNameTaken: false,
      parentId: 0,
      quantityRequired: null,
      showLoader: false,
      snackbarSuccess: {
        text: 'Erfolgreich gespeichert',
        icon: 'mdi-check-circle',
        color: 'success'
      },
      snackbarInfo: {
        text: 'Erfolgreich gespeichert. Nicht alle LoginUser vorhanden',
        icon: 'mdi-information',
        color: 'orange'
      },
      headers: [
        { text: 'Bezeichnung', value: 'name' },
        { text: 'Beschreibung', value: 'description' },
        {
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'end'
        },
      ]
    }
  },
  methods: {
    async create() {
      await this.fetchUsers
      this.licence = { ...GROUP_INIT_DATA }
      this.licence.quantityTotal = 100000;
      this.products = await APIService.getProducts()
      this.showDialog = true
      for(let i=0; i < this.users.length; i++){
        if(!this.users[i].lastName) continue;
        if(this.users[i].email.includes("info@bz-af.de") || this.users[i].email.includes("info.bfg@bz-af.de") || this.users[i].email.includes("info.komzet@bz-af.de")){
          this.filteredUsers.push(this.users[i]);
        }
      }
    },
    async edit(licence) {
      this.licence = licence
      this.quantityRequired = licence.quantityTotal - licence.quantityRemaining
      this.products = await APIService.getProducts()
      this.showDialog = true
    },
    close() {
      this.licence = { ...GROUP_INIT_DATA }
      this.$refs.productForm.resetValidation()
      this.showDialog = false
    },
    handleSelectionChange(value) {
      if (value === null) {
        this.licence.orderNumber = '';
      }
    },
    productFilter(product, query) {
      return propertySearch(product, query, ['name', 'number'])
    },
    async saveLicence () {
      this.showLoader = true;
      if (!this.$refs.productForm.validate()) {
        return
      }
      await APIService.saveLicence(this.licence)
      if(this.syncGroup){
        await this.doSync();
      }
      this.showDialog = false
      this.$emit('updated')
      this.$refs.snackbar.show(this.snackbarSuccess)
      this.showLoader = false;
      this.close()
    },
    async doSync(){
      await this.$refs.confirmDialog.show({
        title: 'Achtung!',
        text: `Die Synchronisation kann einige Minuten dauern!`,
        confirm: 'Ok, starten'
      })
      this.showLoader = true;
      let res = await APIServiceSync.syncBG2LD(this.licence.orderNumber);
      if(res.noLoginUser.length > 0){
        this.$refs.snackbar.show(this.snackbarInfo)
      }
      this.syncGroup = false;
      this.showLoader = false;
    },
    async clearAssignments(){
      this.licence.orderNumber = "";
      await APIService.updateAssignments(this.licence.id, []);
    },
    requiredRule (fieldName) {
      return [value => {
        return (value && typeof value !=="object" )  || `${fieldName} erforderlich.`
      }]
    },
    userFilter(user, query) {
      return propertySearch(user, query, ['firstName','lastName', 'email','companyName'])
    },
    validateLicenceQuantity() {
      if(this.quantityRequired > this.licence.quantityTotal) {
        this.licence.quantityTotal = this.quantityRequired
      }
    },
    async fetchGroups(){
      this.showLoader = true;
      this.sammelgruppen = await APIService.getAllSammelGruppen();
      this.showLoader = false;
    },
  },
  mounted() {
    this.fetchGroups()
  },
  computed: {
    ...mapState(['users']),
    ...mapActions(['fetchUsers']),
    isLicenceCommentObjectId() {
      const regex = /^[0-9a-fA-F]{24}$/;
      return regex.test(this.licence.comment);
    },
    dummySammelgruppen() {
      return [{ id: null, name: 'Bitte wählen' }, ...this.sammelgruppen];
    },
  },
  watch: {
    'licence.orderNumber': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.syncGroup = true;
      }
    }
  },
}
</script>

<style scoped>

</style>
