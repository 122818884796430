<template>
  <div class="s3m-license_assignment_list">
    <h1>Lizenzen</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="licences"
        :search="search"
        :items-per-page="itemsPerPage"
        @update:options="updateOptions"
        class="elevation-1"
        :hide-default-footer="licences.length < 5"
      >
        <template v-slot:item.dateOfPurchase="{item}">
          {{ item.dateOfPurchase | formatLocaleDate }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
          >
            mdi-account-plus
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <licence-assign-dialog ref="licencesAssignDialog" :user-id="userId" @updated="fetchLicences"/>
    <confirm-dialog ref="confirmDelete"/>
  </div>
</template>

<script>
import LicenceAssignDialog from '@/components/LicenceAssignDialog'
import ConfirmDialog from '@/components/ConfirmDialog'
import APIService from '@/services/APIService'
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";

export default {
  name: 'LicenceList',
  mixins: [vuetifyTableMixin],
  components: { LicenceAssignDialog, ConfirmDialog },
  props: ['userId'],
  data () {
    return {
      licences: [],
      headers: [
        {
          text: 'Lizenznummer',
          value: 'id',
          sortable: false
        },
        {
          text: 'Produkt',
          value: 'product.name',
          sortable: false
        },
        {
          text: 'Anzahl',
          value: 'quantityTotal',
          sortable: false,
          align: 'end'
        },
        {
          text: 'Verfügbar',
          value: 'quantityRemaining',
          sortable: false,
          align: 'end'
        },
        {
          text: 'Kaufdatum',
          value: 'dateOfPurchase',
          sortable: false,
          align: 'end'
        },
        {
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ],
      search: ''
    }
  },
  methods: {
    createUser() {
      this.$refs.licencesAssignDialog.create()
    },
    editItem(licence) {
      this.$refs.licencesAssignDialog.edit({...licence})
    },
    async deleteItem(licence) {
      await this.$refs.confirmDelete.show({
        title: 'Lizenz entfernen',
        text: `Wollen sie die Lizenz <b>${licence.id}</b> entfernen?`,
        confirm: 'entfernen'
      })
      await APIService.deleteLicence(licence)
      await this.fetchLicences()
    },
    pagination(data) {
      console.log("Pagination", data)
    },
    pageCount(data) {
      console.log("PageCount", data)
    },
    async fetchLicences() {
       this.licences = await APIService.getUserLicences(this.userId)
    }
  },
  async beforeRouteEnter(to, from, next) {
    const licences = await APIService.getUserLicences(to.params.userId)
    next(vm => vm.licences = licences)
  },
  async beforeRouteUpdate(to,from,next) {
    this.licences = await APIService.getUserLicences(to.params.userId)
    next()
  }
}
</script>

<style scoped>

</style>
