<template>
  <div class="s3m-product_list">
    <h1>Kurs-Bundles</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">
            <v-btn
              rounded
              color="primary"
              dark
              @click="createUser"
            >
              <v-icon left>mdi-plus</v-icon>
              Kurs-Bundle
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="products"
        :search="search"
        :items-per-page="itemsPerPage"
        @update:options="updateOptions"
        class="elevation-1"
        :hide-default-footer="products.length < 5"
      >
        <template v-slot:item.author="{ item }">
          <v-avatar
              v-if="item.author"
              class="mr-1"
              style="color: white; font-weight: bolder;"
              color="warning"
              size="35"
              small
          >{{item.author.firstName | uppercaseFirstLetter}}{{item.author.lastName | uppercaseFirstLetter}}</v-avatar>
          <template v-if="item.author">{{item.author.firstName}} {{item.author.lastName}}</template>
        </template>
        <template v-slot:item.createdAt="{item}" class="ml-0">
          {{item.updatedAt | formatLocaleDate }}
        </template>
        <template v-slot:item.updatedAt="{item}" class="ml-0">
          {{item.updatedAt | formatLocaleDate }}
        </template>
        <template v-slot:item.actions="{ item }">
          <!--
          <v-icon
              small
              class="mr-2"
              @click="showCertificates(item)"

          >
            mdi-certificate
          </v-icon>
          -->
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              class="mr-2"
              @click="copyItem(item)"
          >
            mdi-content-copy
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <product-edit-dialog ref="productEditDialog" @updated="fetchProducts"/>
    <confirm-dialog ref="confirmDelete"/>
  </div>
</template>

<script>
import ProductEditDialog from '@/components/ProductEditDialog'
import ConfirmDialog from '@/components/ConfirmDialog'
import APIService from '@/services/APIService'
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";

export default {
  name: 'ProductList',
  mixins: [vuetifyTableMixin],
  components: { ProductEditDialog, ConfirmDialog },
  data () {
    return {
      products: [],
      headers: [
        {
          text: 'Kurs-Bundle',
          align: 'start',
          value: 'name',
        },
        {
          text: 'Autor',
          value: 'author',
          sortable: true
        },
        {
          text: 'Erstellt am',
          value: 'createdAt',
          sortable: true,
        },
        {
          text: 'Bearbeitet am',
          value: 'updatedAt',
          sortable: true,
        },

        {
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'end',
          width: 120,
        }
      ],
      search: ''
    }
  },
  methods: {
    copyItem(product) {
      APIService.getProduct(product).then(product => {
        console.log('Product', product)
        product.name = `${product.name} Kopie`
        delete product.id
        delete product.createdAt
        delete product.updatedAt
        delete product.author
        delete product.lastUpdatedBy
        this.$refs.productEditDialog.edit(product)
      })
    },
    createUser() {
      this.$refs.productEditDialog.create(this.$route.params.instance)
    },
    editItem(product) {
      this.$refs.productEditDialog.edit({...product})
    },
    async deleteItem(product) {
      await this.$refs.confirmDelete.show({
        title: 'Kurs-Bundle entfernen',
        text: `Wollen sie <b>${product.name}</b> entfernen?`,
        confirm: 'entfernen'
      })
      await APIService.deleteProduct(product)
      await this.fetchProducts()
    },
    pagination(data) {
      console.log("Pagination", data)
    },
    pageCount(data) {
      console.log("PageCount", data)
    },
    showCertificates(item) {
      console.log("Show Certificates", item)
    },
    async fetchProducts() {
       this.products = await APIService.getProducts()
    }
  },
  async beforeRouteEnter(to, from, next) {
    const products = await APIService.getProducts()
    next(vm => vm.products = products)
  },
  async beforeRouteUpdate(to,from,next) {
    this.products = await APIService.getProducts()
    next()
  }
}
</script>

<style scoped>

</style>
