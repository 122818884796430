<template>
  <div class="s3m-license_list">
    <h1>Lerngruppen</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">
            <v-btn
              rounded
              color="primary"
              dark
              @click="createUser"
            >
              <v-icon left>mdi-plus</v-icon>
              Gruppe
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="licences"
        :search="search"
        :items-per-page="itemsPerPage"
        @update:options="updateOptions"
        class="elevation-1"
        :hide-default-footer="licences.length < 5"
      >
        <template v-slot:item.dateOfPurchase="{item}">
          {{ item.dateOfPurchase | formatLocaleDate }}
        </template>
        <template v-slot:item.owner="{item}">
          {{ item.owner.firstName }} {{ item.owner.lastName }}
        </template>
        <template v-slot:item.orderNumber="{item}">
          <v-icon v-if="isLicenceCommentObjectId(item.orderNumber)" color="green">mdi-check</v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="no-wrap">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              class="mr-2"
              @click="editLicenceAssignment(item)"

          >
            mdi-account-plus
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <licence-edit-dialog ref="licencesEditDialog" @updated="fetchLicences"/>
    <licence-assign-dialog ref="licencesAssignDialog"/>
    <confirm-dialog ref="confirmDelete"/>
  </div>
</template>

<script>
import LicenceEditDialog from '@/components/LicenceEditDialog'
import ConfirmDialog from '@/components/ConfirmDialog'
import APIService from '@/services/APIService'
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";
import LicenceAssignDialog from "@/components/LicenceAssignDialog";
import {mapActions} from "vuex";

export default {
  name: 'LicenceList',
  mixins: [vuetifyTableMixin],
  components: { LicenceEditDialog, ConfirmDialog, LicenceAssignDialog },
  ...mapActions(['fetchUsers']),
  data () {
    return {
      licences: [],
      headers: [
        {
          text: 'Kurs-Bundle',
          value: 'product.name',
          sortable: false
        },
        {
          text: 'Bezeichnung',
          sortable: true,
          value: 'comment',
        },
        {
          text: 'Verb.',
          sortable: true,
          value: 'orderNumber',
        },
        {
          text: 'Erstellt am',
          value: 'dateOfPurchase',
          sortable: true,
          align: 'end'
        },
        {
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'end',
          devider: false
        }
      ],
      search: ''
    }
  },
  methods: {
    createUser() {
      this.$refs.licencesEditDialog.create()
    },
    editItem(licence) {
      this.$refs.licencesEditDialog.edit({...licence})
    },
    editLicenceAssignment(licence) {
      this.$refs.licencesAssignDialog.edit({...licence})
    },
    async deleteItem(licence) {
      await this.$refs.confirmDelete.show({
        title: 'Gruppe entfernen',
        text: `Wollen sie die Gruppe <b>${licence.id}</b> entfernen?`,
        confirm: 'entfernen'
      })
      await APIService.deleteLicence(licence)
      await this.fetchLicences()
    },
    pagination(data) {
      console.log("Pagination", data)
    },
    pageCount(data) {
      console.log("PageCount", data)
    },
    async fetchLicences() {
       this.licences = await APIService.getLicences()
    },
    isLicenceCommentObjectId(value) {
      const regex = /^[0-9a-fA-F]{24}$/;
      return regex.test(value);
    }
  },
  async beforeRouteEnter(to, from, next) {
    const licences = await APIService.getLicences()
    next(vm => vm.licences = licences)
  },
  async beforeRouteUpdate(to,from,next) {
    this.licences = await APIService.getLicences()
    next()
  }
}
</script>

<style scoped>
</style>
